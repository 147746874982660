@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  position: relative;
}

.custom-bullet {
  position: relative;
  padding-left: 14px;
}

.custom-bullet::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 1em;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.error-container {
  svg * {
    fill: #f26739;
  }
}

.hole-ticket {
  height: 78px;

  background: radial-gradient(circle at 0% 50%, transparent 70%, white 30%) no-repeat,
    radial-gradient(circle at 100% 50%, transparent 70%, white 30%) no-repeat;
}

.bg-scoop-left {
  background: radial-gradient(circle at 0% 50%, transparent 70%, white 30%) no-repeat;
}

.bg-scoop-left .shadow {
  box-shadow: inset -4px 1px 4px 0px #afafaf40;
}

.bg-scoop-right {
  background: radial-gradient(circle at 100% 50%, transparent 70%, white 30%) no-repeat;
  background: radial-gradient(100% 50%, transparent 70%, white 30%) circle no-repeat;
}

.bg-scoop-right .shadow {
  box-shadow: inset 4px 1px 4px 0px #afafaf40;
}

body:has(.drawer) {
  overflow: hidden;
}

body:has(.pwa-install-guide):not(:has(.no-data)) {
  padding-bottom: 296px;
}

.ios .min-h-screen {
  min-height: 100dvh !important;
}
.ios .h-screen {
  height: 100dvh !important;
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.clip-path-top {
  clip-path: inset(0px -4px -4px -4px);
}

.clip-path-bottom {
  clip-path: inset(-4px -4px 0 -4px);
}
